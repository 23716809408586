.breadcrumb-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    max-width: 1200px; /* Increased max-width to accommodate landscape images */
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px; /* Move to the right */
    padding-top: 20px; /* Move down */
}


.breadcrumb {
    font-size: 14px;
}

.breadcrumb a {
    color: #ff5a5f;
    text-decoration: none;
}

.breadcrumb a:hover {
    text-decoration: underline;
}

.header-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header-text {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 5px;
}

.subheader-text {
    font-size: 18px;
    color: gray;
}
.building-images-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px; /* Space between the big image and small images */
}

.main-image-container {
    flex: 2;
}

.small-images-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between the two small images */
    flex: 1;
}

.big-building-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.small-building-image {
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-radius: 10px;
}

.building-imagedETAIL {
    width: 100%;
    max-width: 1200px; /* Increased max-width to accommodate landscape images */
    height: 400px; /* Fixed height to create a landscape effect */
    overflow: hidden; /* Ensures the image doesn't overflow */
    margin-bottom: 20px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.building-imagedETAIL img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire container without distortion */
    border-radius: 10px;
}

.apartment-details {
    background-color: #fff;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.price-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.price-box {
    grid-area: price;
    padding: 20px;
    text-align: left;
    font-size: 36px;
    font-weight: bold;
    background-color: #f9f9f9;
}

.quick-summary-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.details-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}
.apartment-details-grid {
    display: grid;
    grid-template-areas: 
        "price description"
        "summary amenities";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    color: #333;
}
.quick-summary {
    grid-area: summary;
    text-align: left; /* Change to align text to the left */
}
.quick-summary .label {
    font-weight: bold;
    margin-right: 10px; /* Space between the label and value */
    display: inline-block;
    width: 90px; /* Adjust width to align labels and values */
}

.quick-summary .value {
    display: inline-block;
    text-align: right; /* Align value text to the right */
    width: calc(100% - 190px); /* Adjust the width considering the margin */
}
.property-description {
    grid-area: description;
    padding-left: 20px;    text-align: left; /* Change to align text to the left */
}



.amenities {
    grid-area: amenities;
    padding-left: 20px;  text-align: left; /* Change to align text to the left */
}

.amenities .h33 {
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.amenities .h33::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: #ff5a5f;
    bottom: 0;
    left: 0;
}
.h33{
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    font-weight: bold;
}

.h33::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: #ff5a5f;
    bottom: 0;
    left: 0;
}

.amenities-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.amenities li {
    color: #555;
}
.amenities-list {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
}

.amenities-list li {
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center-align items vertically */
    margin-bottom: 10px; /* Add some space between each list item */
}

.amenities-list img {
    width: 30px; /* Adjust the image size */
    height: 40px; /* Ensure the image remains square */
    margin-right: 10px; /* Add space between the image and text */
}

.amenities-list span {
    font-weight: 700; /* Bold the hyphen as requested */
}

@media screen and (max-width: 768px) {
    .amenities-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Create two equal columns */
        grid-gap: 10px; /* Space between items */
        padding: 0;
        margin: 0;
        width: 100%; /* Ensure it takes the full width of the container */
    }

    .amenities-list li {
        list-style-type: none;
        padding: 8px 0; /* Adjust padding for a more compact look */
        font-size: 14px; /* Decrease font size for better fit */
        color: #333; /* Consistent text color */
        word-wrap: break-word; /* Ensure long words break to the next line */
    }
}



.breadcrumb span {
    margin: 0 5px; /* Adjust the space around the slashes */
    color: #000; /* Optional: Change the color of the slashes */
}

.booking-section {
    background-color: #444;
    color: #fff;
    padding: 20px;
    margin-top: 20px;
}

.availability {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
}

.pricing {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0;
}

.book-button {
    background-color: #ff5a5f;
    color: white;
    border: none;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
}

.book-button:disabled {
    background-color: #9c9c9c;
    cursor: not-allowed;
}
/* Existing CSS */

/* Additional media queries for mobile */
@media screen and (max-width: 768px) {
    .breadcrumb-container {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    .header-container {
        margin-bottom: 10px;
    }

    .breadcrumb {
        font-size: 12px;
        margin-top: 10px;
    }

    .subheader-text {
        margin-bottom: 10px;
    }

    .building-image {
        height: 250px;
        width: 95%;/* Adjust height for mobile */
    }

    .room-card-container {
        padding: 10px;
    }

    .room-card {
        margin-bottom: 20px;
        width: 120%;
    }

    .breadcrumb-container .breadcrumb {
        margin-top: 10px;
    }
}

@media screen and (max-width: 768px) {
    .apartment-details-grid {
        display: block; /* Stack elements vertically on mobile */
        padding: 10px;
    }

    .price-box {
        order: 1; /* Ensure the booking section is on top */
    }

    .property-description {
        order: 2; /* Move the property description under the booking section */
        margin-top: 20px;
    }

    .quick-summary {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .quick-summary div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
    }

    .quick-summary .label {
        font-weight: bold;
        margin-right: 10px;
        width: auto; /* Let the label width adjust based on content */
    }

    .quick-summary .value {
        margin-left: auto; /* Push the value span to the far right */
        text-align: right;
        width: auto;
    }

    .amenities {
        order: 4; /* Move amenities under the quick summary */
        margin-top: 20px;
    }

    /* Adjustments to make sure the layout is mobile-friendly */
    .breadcrumb-container {
        padding-left: 10px; 
        padding-right: 10px;
    }

    .header-container {
        padding-bottom: 10px;
    }

    .breadcrumb {
        font-size: 12px;
    }

    .building-image {
        height: 200px; /* Adjust height for mobile */
        margin-bottom: 10px;
    }
}
.react-datepicker-wrapper {
    width: 100%; /* Make sure it takes the full width of its container */
}

.react-datepicker {
    font-family: 'Arial', sans-serif;
    border-radius: 8px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
    background-color: #ff5a5f;
    border-bottom: 1px solid #ff5a5f;
    padding-top: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.react-datepicker__current-month {
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #333;
    font-size: 0.9rem;
    font-weight: bold;
}

.react-datepicker__day:hover,
.react-datepicker__day--selected {
    background-color: #ff5a5f;
    color: white;
    border-radius: 50%;
}

.react-datepicker__day--keyboard-selected {
    background-color: #66bb6a;
    color: white;
    border-radius: 50%;
}

.react-datepicker__day--today {
    font-weight: bold;
    color: #ff5a5f;
}

.react-datepicker__close-icon::after {
    background-color: #ff5252;
    color: white;
    border-radius: 50%;
    font-size: 14px;
    padding: 2px 5px;
}

.react-datepicker__triangle {
    display: none; /* Hide the small triangle on top of the calendar */
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
}

.react-datepicker__input-container input:focus {
    border-color: #ff5a5f;
    outline: none;
}

.react-datepicker__input-container input::placeholder {
    color: #aaa;
}

.book-now-button {
    background-color: #ff5a5f; /* Green background */
    color: white; /* White text */
    padding: 12px 24px; /* Padding for a comfortable button size */
    font-size: 16px; /* Slightly larger font for emphasis */
    font-weight: bold; /* Bold text */
    border: none; /* Remove default border */
    border-radius: 25px; /* Fully rounded corners */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition effects */
    width: 100%; /* Full width of the container */
    text-align: center; /* Center text */
    margin-top: 20px; /* Space above the button */
}

.book-now-button:hover {
    background-color: #45a049; /* Darker green on hover */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Slightly larger shadow on hover */
}

.book-now-button:disabled {
    background-color: #9c9c9c; /* Grey background when disabled */
    cursor: not-allowed; /* Change cursor to indicate non-clickable */
    box-shadow: none; /* Remove shadow when disabled */
}

.book-now-button:active {
    background-color: #3e8e41; /* Even darker green on click */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Smaller shadow on click */
    transform: translateY(2px); /* Slightly move down on click for a pressing effect */
}
