.containerinvoice {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap; /* Allow wrapping for mobile */
}

.booking-form {
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 10px;
    width: 55%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    color: #333333;
}


.booking-summary {
    background-color: #f9f9f9;
    padding: 40px;
    border-radius: 10px;
    width: 35%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    color: #333333;
    max-height: 700px;
}

.step {
    font-weight: bold;
    font-size: 20px;
    color: #d9534f; /* Use a red color to highlight the emergency nature */
    margin-bottom: 20px;
    margin-top: 40px; /* Add space above to lower the text */
    text-align: left; /* Center the text */
}

.step2 {
    font-weight: bold;
    font-size: 20px;
    color: #d9534f;
    margin-bottom: 20px;
    margin-top: 40px; /* Add space above to lower the text */
    text-align: left; /* Align the text to the left */
}
.emerg-row {
    display: flex;
    justify-content: space-between; /* Adjusts space between the two elements */
    gap: 20px; /* Optional: Adds space between the two elements */
    margin-bottom: 20px; /* Adds space below each row */
}

/* General styles for emergp */
.emergp {
    font-size: 16px !important;
    color: #5905f5;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 10px;
    flex: 1;
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
    .emergp {
        font-size: 10px !important; /* Force the size change */
    }
}



h4 {
    font-size: 16px;
    color: #333333;
    margin-bottom: 10px; text-align: left;
}

.emergp {
    font-size: 10px; /* Set a smaller font size */
    color: #5905f5;
    line-height: 1.6;
    text-align: left;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .containerinvoice {
        flex-direction: column; /* Stack the items vertically */
    }

    .booking-form,
    .booking-summary {
        width: 95%; /* Expand width to 95% of the container */
        margin: 0 auto 20px auto; /* Center align and add spacing below */
    }
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.header .image {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.title {
    text-align: left;
    margin-top: 10px;
}

.title h2 {
    margin: 0;
    font-size: 28px;
    color: #222222;
}

.title p {
    margin: 5px 0 0 0;
    color: #555555;
    font-size: 18px;
}

@media (max-width: 768px) {
    .header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        flex-direction: row;
    }

    .header .image {
        width: 80px; /* Adjust image size for mobile */
        height: 80px;
        border-radius: 10px;
        margin-right: 10px;
        align-content: left; /* Adjust spacing between image and text */
    }

    .title {
        flex-grow: 1; /* Allow title to take up available space */
        display: flex;
        flex-direction: column;
        justify-content: center; /* Center the text vertically */
    }

    .badge {
        align-self: flex-start; /* Align badge to the top left, same level as image */
        margin-bottom: 0; /* Remove bottom margin to align with the image */
        margin-left: 10px; /* Add some space from the left edge */
        font-size: 14px; /* Slightly increase font size for readability */
    }

    .title h2 {
        margin: 0;
        font-size: 20px; /* Adjust font size for mobile */
        color: #222222;
        width: 100%;
        line-height: 1; /* Make the title span the full width */
    }

    .title h2 span {
        display: block; /* Display the apartment number on a new line */
        font-size: 18px; /* Adjust the size to differentiate */
        color: #555; /* Slightly lighter color for contrast */
    }

    .title p {
        margin: 5px 0 0 0;
        color: #555555;
        font-size: 16px; /* Adjust font size for mobile */
    }

    .containerinvoice {
        flex-direction: column; /* Stack the items vertically */
    }

    .booking-form,
    .booking-summary {
        width: 95%; /* Expand width to 95% of the container */
        margin: 0 auto 20px auto; /* Center align and add spacing below */
    }
}

select {
    width: 100%;
    padding: 12px;
    margin: 20px 0;
    font-size: 16px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    background-color: #ffffff;
    color: #333333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

select:focus {
    outline: none;
    border-color: #ff5a5f;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.badge {
    background-color: #ff5a5f00;
    color: #000000;
    padding: 8px 12px;
    font-size: 10px;
    border-radius: 4px;
    margin-bottom: 8px;
    border: 1px solid #000;
    display: inline-block;
}

.step {
    font-weight: bold;
    margin-bottom: 20px;
    color: #ff5a5f;
    font-size: 20px;
}

.booking-form h3 {
    margin-bottom: 15px;
    color: #333333;
    font-size: 24px;
}

.booking-form p {
    margin-bottom: 25px;
    color: #555555;
    font-size: 18px;
}

.booking-form a {
    color: #ff5a5f;
    text-decoration: none;
}

.booking-form a:hover {
    text-decoration: underline;
}

.booking-form form {
    display: flex;
    flex-direction: column;
}

.booking-form input {
    padding: 14px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    background-color: #ffffff;
    color: #333333;
    font-size: 18px;
}

.booking-form input::placeholder {
    color: #aaaaaa;
    font-size: 16px;
}

.booking-form button {
    padding: 14px;
    background-color: #ff5a5f;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    transition: background-color 0.3s ease;
}

.booking-form button:hover {
    background-color: #0056b3;
}

.check-in-out {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dddddd;
}

.check-arrow p {
    margin: 0 15px;
    font-size: 20px;
    color: #555555;
}

.booking-summary .check p {
    margin: 0;
    font-size: 18px;
    color: #555555;
}

.booking-summary .check strong {
    font-size: 20px;
    color: #333333;
}

.details {
    margin-bottom: 20px;
}

.detail-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.detail-row .label {
    font-weight: bold;
    font-size: 18px;
    color: #555555;
}

.detail-row .value {
    font-size: 18px;
    color: #333333;
}

.cost {
    margin-bottom: 20px;
}

.cost-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.cost-row .label {
    font-weight: bold;
    font-size: 18px;
    color: #555555;
}

.cost-row .value {
    font-size: 18px;
    color: #333333;
}
ol {
    list-style-type: decimal; /* Default numbering style */
    padding-left: 20px; /* Adds some padding to the left of the list */
    text-align: left; /* Ensures text alignment to the left */
}

ol li {
    margin-bottom: 10px; /* Adds some space between list items */
    font-size: 16px; /* Sets the font size */
    color: #333333; /* Sets the text color */
    line-height: 1.6; /* Sets the line height for better readability */
    text-align: left; /* Ensures text alignment to the left */
}

.due-today {
    text-align: center;
    background-color: #ff5a5f;
    padding: 20px;
    border-radius: 8px;
    margin-top: 25px;
    color: #ffffff;
}

.due-today .amount {
    font-size: 26px;
    font-weight: bold;
    margin-top: 10px;
}
