/* General Page Styles */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f9; /* Background color */
}

.login-container {
    background-color: #ffffff; /* White background for the inner container */
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 380px;
}

.logo {
    width: 160px;
    margin-bottom: 20px;
}

/* Input Field and Button Styles */
.input-grouplogin {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.input-fieldlogin {
    width: 100%;
    padding: 12px 20px; /* Adjust padding */
    margin-bottom: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 29px; /* Creates a rounded pill shape */
    font-size: 16px;
    background-color: #f9f9f925;
}

.input-button {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 29px;
    background-color: #ff5a5f;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s ease;
}

.input-button:hover {
    background-color: #005bb5;
}

.arrow-icon {
    font-weight: bold;
}

/* Additional Options and Links */
.options {
    margin-top: 20px;
    color: #333;
}

.options label {
    font-size: 14px;
    color: #666;
}

.links {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    gap: 20px;
    font-size: 14px;
}

.links a {
    color: #ff5a5f;
    text-decoration: none;
}

.links a:hover {
    text-decoration: underline;
}
