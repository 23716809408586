* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}



.containerssss {
  max-width: 1200px;
  margin: 40px auto;
  display: flex;
  gap: 20px;
  flex-direction: row; /* Default for desktop */
}

.info-section {
  flex: 2;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;
  text-align: left; 
}

.info-section h2 {
  margin-bottom: 20px;
  text-align: left; 
}

.info-item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.info-item label {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left; 
}

.info-item span {
  color: #888;
  margin-top: 5px;
  text-align: left; 
}

.info-item a {
  align-self: flex-end;
  color: #ff5a5f;
  text-decoration: none;
  font-weight: bold;
  margin-top: 5px;
}

.info-item a:hover {
  text-decoration: underline;
}

/* Input fields styling */
.input-group {
  display: flex;
  gap: 10px; /* Space between input fields */
  margin-top: 10px;
}

.input-field {
  flex-basis: 45%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 45%;
}

/* Custom File Input Styling */
.file-input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.file-input {
  display: none;
}

.file-label {
  display: inline-block;
  background-color: #ff5a5f;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.file-label:hover {
  background-color: #ff7a7b;
}

.file-name {
  color: #888;
  font-size: 14px;
}

/* Right section styling */
.help-section {
  flex: 1;
  background-color: white;
  padding: 20px;
  height: 50%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.help-box {
  display: flex;
  align-items: flex-start; /* Align text with the start of the image */
  margin-bottom: 20px;
}

.help-box img {
  max-width: 50px;
  margin-right: 20px;
}

.help-text {
  flex-grow: 1;
}

.help-text h3 {
  margin-bottom: 5px;
  color: #ff5a5f;
}

.help-text p {
  color: #555;
  margin-top: 0;
}

/* Line between help boxes */
hr {
  border: 0;
  height: 1px;
  background-color: #e0e0e0;
  margin: 20px 0;
}

/* Media query for mobile */
@media (max-width: 768px) {
  .containerssss {
      flex-direction: column; /* Stack the sections vertically */
  }
  
  .help-section {
      margin-top: 20px; /* Adds some space between info and help sections */
  }
}
