.register-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f4f4f4;
}

.register-container {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}
/* Continue Button */
.continue-button {
    background-color: #ff5a5f;
    color: #fff;
    margin-top: 20px;
    width: 100%;
    display: inline-block;
    text-align: center;
}

.continue-button:hover {
    background-color: #005bb5;
}
.spinner {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-left-color: #ffffff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 8px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Back Button */
.back-button {
    width: 100%;
    padding: 10px;
    border: none;
    border: 1px solid #ff5a5f;
    border-radius: 5px;
    color: #ff5a5f;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

.back-button:hover {
    background-color: #e7e7e9;
}
.register-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}
.password-group {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.password-group input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.form-group {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
}

.form-group label {
    font-size: 14px;
    color: #888;
    margin-bottom: 5px;
}

.register-button {
    width: 100%;
    padding: 10px;
    background-color: #ff5a5f;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.register-button:hover {
    background-color: #0056b3;
}

.login-link {
    color: #ff5a5f;
    text-decoration: none;
    font-weight: bold;
}

.login-link:hover {
    text-decoration: underline;
}

.register-form p {
    text-align: center;
    margin-top: 20px;
    color: #666;
}
.form-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}