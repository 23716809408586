.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 15px;
    border-radius: 8px;
    max-width: 450px;
    width: 100%;
    font-size: 14px; /* Reduce the font size */
}

.form-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
}

.form-group input {
    width: 46%; /* Adjust the width for smaller inputs */
    padding: 6px 8px; /* Smaller padding for input fields */
    font-size: 12px; /* Reduce input font size */
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group label {
    font-size: 12px; /* Reduce label font size */
}

button {
    padding: 8px 12px; /* Smaller button padding */
    background-color: #ff5a5f;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 12px; /* Smaller button font size */
    cursor: pointer;
    margin-right: 8px;
}

button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
}
