.slider_container {
    position: relative;
    width: 100%;
    height: 65rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }
  
  .slider {
    position: relative;
    width: 500%; /* Adjusted width to fit all slides */
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    animation: 30s cubic-bezier(1, 0.95, 0.565, 1) sliding infinite;
  }
  
  .slide {
    position: relative;
    min-width: 100%;
    height: 100%;
    overflow: hidden; /* Ensures the content inside does not overflow */
  }
  
  .slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    z-index: 1;
    pointer-events: none;
  }
  
  .caption {
    position: absolute;
    left: 0;
    bottom: 15%;
    font-size: 5rem;
    font-weight: 600;
    color: white;
    text-transform: capitalize;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    padding: 1rem 5rem;
    border-radius: 0 2rem 2rem 0;
    z-index: 2; /* Ensures the caption is above the gradient overlay */
  }
  
  @keyframes sliding {
    0% {
      transform: translateX(0%);
    }
    20% {
      transform: translateX(0%);
    }
    25% {
      transform: translateX(-100%);
    }
    45% {
      transform: translateX(-100%);
    }
    50% {
      transform: translateX(-200%);
    }
    70% {
      transform: translateX(-200%);
    }
    75% {
      transform: translateX(-300%);
    }
    95% {
      transform: translateX(-300%);
    }
    100% {
      transform: translateX(-400%);
    }
  }
  